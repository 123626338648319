import config from "./config";

const baseUrl = config.apiUrl;

const formJson = (items) => {
  const form_data = new FormData();
  for (var key in items) {
    form_data.append(key, items[key]);
  }
  return form_data;
};

const authProvider = {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    const request = new Request(baseUrl + "?section=home&action=login", {
      method: "POST",
      body: formJson({ username, password }),
      //headers: new Headers({ "Content-Type": "application/json" }),
    });
    return await fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((res) => {
        if (res.hasOwnProperty("errMsg")) {
          //throw new Error(res.errMsg);
          return Promise.reject(new Error(res.errMsg));
        }
        if (res.hasOwnProperty("fineMsg")) {
          localStorage.setItem("admin", JSON.stringify(res.fineMsg));
          localStorage.setItem("username", res.fineMsg.username);
          localStorage.setItem("token", res.fineMsg.token);
          return Promise.resolve(); //{ redirectTo: "/users" }
        }
      });
    /* .catch(() => {
        throw new Error("Network error jon");
      }); */

    /* if (json.error) {
      // The notification will show what's in { error: "message" }
      Promise.reject(new Error(json.error.message));
      return;
    } */
  },
  // called when the user clicks on the logout button
  logout: async () => {
    localStorage.removeItem("username");
    localStorage.removeItem("admin");
    localStorage.removeItem("token");
    const request = new Request(baseUrl + "?section=home&action=logout", {
      method: "GET",
    });
    await fetch(request).then((response) => {
      return Promise.resolve();
    });
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 403) {
      return Promise.resolve(new Error("You do not have permission for this."));
    }
    if (status === 401) {
      localStorage.removeItem("username");
      localStorage.removeItem("admin");
      localStorage.removeItem("token");
      return Promise.reject(new Error("Session expired, please login again."));
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: ({ resource }) => {
    return localStorage.getItem("username")
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const admin = JSON.parse(localStorage.getItem("admin"));
    const access = ["super", "admin", "user", "N/A"];
    return access[admin.u_type]
      ? Promise.resolve({
        'access': access[admin.u_type], 
        'reportRegionsExist': admin.reportRegionsExist
      })
      : Promise.reject();
  },
};

export default authProvider;
