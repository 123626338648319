import * as React from "react";
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  PasswordInput,
  UrlField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  Create,
  required,
  ListButton,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  TopToolbar,
  Pagination,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  usePermissions,
} from "react-admin";
import AdminField from "./AdminField";
import TrackField from "./TrackField";
import PassField from "./PassField";

const ProjectEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="< Zurück" />
  </TopToolbar>
);

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);

export const UserList = () => (
  <List perPage={1000} pagination={<ProjectPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="username" />
      <AdminField source="u_type" label='User Role' />
      <TextField source="uc_names" label="User Categories" />
      <EditButton />
      <TrackField source="id" />
    </Datagrid>
  </List>
);

export const UserEdit = (props) => {
  const { permissions } = usePermissions(); 
  return(
  <Edit title="Edit User" actions={<ProjectEditActions />}>
    <SimpleForm>
      <TextInput disabled source="id" variant="outlined" />
      <TextInput label="Full Name" source="name" validate={required()} />
      <TextInput
        label="Username / email"
        source="username"
        validate={required()}
      />
      <PasswordInput source="pass" label="Password" variant="outlined" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.u_type > 0 && (
            <div>
              <SelectInput
                source="u_type"
                label="User Role"
                choices={[
                  { id: 1, name: "Admin" },
                  { id: 2, name: "User" },
                ]}
              />
              <ReferenceArrayInput
                source="u_category"
                reference="userCategories"
              >
                <AutocompleteArrayInput optionText="name" label="User Categories" validate={required()} />
              </ReferenceArrayInput>
              <ReferenceArrayInput source="cregions" reference="regions">
                <AutocompleteArrayInput label="Can Change Regions" />
              </ReferenceArrayInput>
              <ReferenceArrayInput source="vregions" reference="regions">
                <AutocompleteArrayInput label="Only View Regions" />
              </ReferenceArrayInput>
              {permissions.access === "super" ?
              <ReferenceArrayInput source="reportRegions" reference="regions">
                <AutocompleteArrayInput label="Umasatz and Fertiggestellt Regions" sx={{minWidth: '350px'}}/>
              </ReferenceArrayInput> : null}
            </div>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)};

export const UserCreate = (props) => (
  <Create title="Add New User" {...props} actions={<ProjectEditActions />}>
    <SimpleForm>
      <TextInput label="Full Name" source="name" validate={required()} />
      <TextInput
        label="Username / email"
        source="username"
        validate={required()}
      />
      <PasswordInput label="Password" source="password" validate={required()} />
      <SelectInput
        validate={required()}
        label="User Role"
        source="u_type"
        choices={[
          { id: 1, name: "Admin" },
          { id: 2, name: "User" },
        ]}
      />
      <ReferenceInput
        source="u_category"
        reference="userCategories"
        validate={required()}
      >
        <AutocompleteInput optionText="name" label="User Category"/>
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
