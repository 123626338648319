import React, { useRef, useState, useEffect } from "react";
import { useRedirect, useRefresh, SelectInput } from "react-admin";
import { Button, TextField } from "@mui/material";
import dataProvider from "../dataProvider";
import NativeSelect from "@mui/material/NativeSelect";

const ActionInline = ({ field, record, id }) => {
  const [inputValue, setInputValue] = useState(record[field.props.source]);
  const redirect = useRedirect();
  const refresh = useRefresh();
  const submitChange = () => {
    const tmpNow = document.getElementById(`input-${id}-${field.props.source}`);
    if (tmpNow) {
      tmpNow.style.opacity = "0.5";
    }
    let tmpInputValue = inputValue;
    /* if (field.props.source === "action_date") {
      let tmpVal = tmpInputValue.split("/");
      tmpInputValue = tmpVal[2] + "-" + tmpVal[1] + "-" + tmpVal[0];
    } */

    let tmpData = { id: record.id, data: {} };
    tmpData.data.id = record.id;
    tmpData.data[field.props.source] = tmpInputValue;
    dataProvider.update("projects", tmpData).then((data) => {
      tmpNow.style.opacity = "1";
      tmpNow.style.display = "none";
      refresh();
    });
  };
  useEffect(() => {
    /* if (field.props.source === "action_date") {
      const d = new Date(record[field.props.source]);
      setInputValue(
        d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear()
      );
    } */
  }, []);
  return (
    <div
      id={`input-${id}-${field.props.source}`}
      className="akInlineField"
      style={{ display: "none" }}
    >
      {field.props.source === "action_date" ? (
        <TextField
          label={field.props.label}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          variant="outlined"
          type="date"
          style={{
            background: "#fff",
          }}
        />
      ) : field.props.source == "farbe" ? (
        <NativeSelect
          label="Farbe (color)"
          defaultValue={inputValue}
          className="list_farbe"
          onChange={(e) => setInputValue(e.target.value)}
        >
          <option value={1}> . . . . . . . . . </option>
          <option value={2}></option>
          <option value={3}></option>
          <option value={4}></option>
          <option value={5}></option>
          <option value={6}></option>
          <option value={7}></option>
          <option value={8}></option>
          <option value={9}></option>
        </NativeSelect>
      ) : (
        <TextField
          label={field.props.label}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          variant="outlined"
          multiline={field.props.source == "action"}
          style={{
            width: field.props.source == "action" ? "800px" : "100%",
            background: "#fff",
          }}
        />
      )}

      <Button variant="contained" onClick={submitChange}>
        Update
      </Button>
    </div>
  );
};

export default ActionInline;
