import * as React from "react";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import logo from "../images/logo.png";
import { useGetList, usePermissions } from "react-admin";
import { NeukundenRegion, NochNichtAbgerechnet, TopKunden, UmsatzRegion, Worklist } from "./DashboardCards/AllCards";

const Dashboard = () => {
  const { permissions } = usePermissions();
  const { data: regionsData, isLoading: loadingRegions } = useGetList('regions');
  if (permissions.access === "super") {
    return (
      <Card>
        <CardHeader
          title="Herzlich willkommen zurück!"
          sx={{ "& .MuiCardHeader-title": { fontSize: "2.5rem" } }}
          style={{ textAlign: "center" }}
        />
        <CardContent sx={{ backgroundColor: "#f4f4f4" }}>
          {!loadingRegions && (
            <Grid key={2} container spacing={2} sx={{ px: 3, pb: 4, pt: 3 }}>
              <Grid item xs={12}>
                <NeukundenRegion regions={regionsData} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <UmsatzRegion regions={regionsData} title={'umsatz'} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <UmsatzRegion regions={regionsData} title={'umsatzFertiggestellt'} />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TopKunden regions={regionsData} />
              </Grid>
              <Grid item xs={12} lg={8}>
                <Worklist />
              </Grid>
              <Grid item xs={12} lg={4}>
                <NochNichtAbgerechnet regions={regionsData} />
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card >
    );
  } else {
    return (
      <Card style={{ textAlign: "center" }}>
        <CardHeader
          title="Herzlich willkommen zurück!"
          sx={{ "& .MuiCardHeader-title": { fontSize: "2.5rem" } }}
        />
        <CardContent>
          <img src={logo} alt="logo" style={{ maxWidth: "40%" }} />
        </CardContent>
      </Card>
    );
  }
}

export default Dashboard;
