import * as React from "react";
import { TableCell, TableRow, Table, TableBody } from "@mui/material";
import { Box, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "20px 25px",
  borderRadius: "10px",
  margin: "5px",
});
const TitleBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "10px"
});

const TrackingCards = ({ record }) => {

  if (record.action) {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>{record.action}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
  if (record.acc_manager == null && record.lo_berichte == null && record.angebote == null && record.rechnungen == null) {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>No data for this user</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
  return (
    <Box py={3}>
      {record.acc_manager &&
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} >
          <Typography variant="h4" sx={{ px: 4 }}>
            Account Manager
          </Typography>
          <Grid key={1} container spacing={2} sx={{ px: 3, pb: 4, pt: 3 }}>
            <TrackingCard name="Revenue" trackingValue={record.acc_manager.total_revenue} />
            <TrackingCard name="Project Edits" trackingValue={record.acc_manager.edits} />
            <TrackingCard name="Project Created" trackingValue={record.acc_manager.projects_created} />
          </Grid>
        </Box>
      }
      {record.lo_berichte &&
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} >
          <Typography variant="h4" sx={{ px: 4 }}>
            LO-Berichte
          </Typography>
          <Grid key={2} container spacing={2} sx={{ px: 3, pb: 4, pt: 3 }}>
            <TrackingCard name="Assigned Tasks" trackingValue={record.lo_berichte.assigned_tasks} />
            <TrackingCard name="Completed Tasks" trackingValue={record.lo_berichte.completed_tasks} />
            <TrackingCard name="Overall Assigned" trackingValue={record.lo_berichte.all_assigned_tasks} />
            <TrackingCard name="Overall Completed" trackingValue={record.lo_berichte.all_completed_tasks} />
          </Grid>
        </Box>
      }
      {record.angebote &&
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} >
          <Typography variant="h4" sx={{ px: 4 }}>
            Angebote
          </Typography>
          <Grid key={3} container spacing={2} sx={{ px: 3, pb: 4, pt: 3 }}>
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TrackingCard name="Assigned Tasks" trackingValue={record.angebote.assigned_tasks} customGrid={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TrackingCard name="Completed Tasks" trackingValue={record.angebote.completed_tasks} customGrid={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TrackingCard name="Umsatz" trackingValue={record.angebote.umsatz} customGrid={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TrackingCard name="Deckungsbeitrag" trackingValue={record.angebote.deckungsbeitrag} customGrid={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <TrackingCard name="Percentage" trackingValue={record.angebote.percentage} customGrid={true} />
            </Grid>
            <TrackingCard name="All Angebote" trackingValue={record.angebote.total_angebote} />
            <TrackingCard name="Overall Umsatz" trackingValue={record.angebote.all_umsatz} />
            <TrackingCard name="Overall Deckungsbeitrag" trackingValue={record.angebote.all_deckungsbeitrag} />
            <TrackingCard name="Overall Percentage" trackingValue={record.angebote.all_percentage} />
          </Grid>
        </Box>
      }
      {record.rechnungen &&
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} >
          <Typography variant="h4" sx={{ px: 4 }}>
            Rechnungen
          </Typography>
          <Grid key={4} container spacing={2} sx={{ px: 3, pb: 4, pt: 3 }}>
            <TrackingCard name="Invoices" trackingValue={record.rechnungen.wrote_invoices} />
            <TrackingCard name="Revenue" trackingValue={record.rechnungen.total_revenue} />
            <TrackingCard name="Overall Invoices" trackingValue={record.rechnungen.all_wrote_invoices} />
            <TrackingCard name="Overall Revenue" trackingValue={record.rechnungen.all_total_revenue} />
          </Grid>
        </Box>
      }
    </Box>

  );
};

export default TrackingCards;

const TrackingCard = ({ name, trackingValue, customGrid = false }) => {
  if (customGrid) {
    return (
      <StyledPaper elevation={3}>
        <TitleBox>
          <Typography variant="h6" sx={{ color: "#707070" }}>{name}</Typography>
        </TitleBox>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} flexWrap={'wrap'}>
          <Typography variant="h6" color={"#8C8C8C"} fontSize={'16px'}>Total</Typography>
          <Typography variant="h4">{trackingValue}{name === 'Percentage' || name === 'Overall Percentage' ? '%' : ''}</Typography>
        </Box>
      </StyledPaper>
    );
  } else {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <StyledPaper elevation={3}>
          <TitleBox>
            <Typography variant="h6" sx={{ color: "#707070" }}>{name}</Typography>
          </TitleBox>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} flexWrap={'wrap'}>
            <Typography variant="h6" color={"#8C8C8C"} fontSize={'16px'}>Total</Typography>
            <Typography variant="h4">{trackingValue}{name === 'Percentage' || name === 'Overall Percentage' ? '%' : ''}</Typography>
          </Box>
        </StyledPaper>
      </Grid>
    );
  }
}