import React from "react";
import { AppBar } from "react-admin";
import { Typography, Toolbar } from "@mui/material";

import logo from "../images/logo.png";

const CustomAppBar = (props) => {
  return (
    <AppBar {...props} color="secondary">
      <Typography
        variant="h6"
        color="inherit"
        style={{
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          marginLeft: -10,
        }}
        id="react-admin-title"
      />
      <Toolbar style={{ background: "#fff" }}>
        <img src={logo} alt="logo" style={{ maxWidth: "94px" }} />
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
