import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px 10px",
    borderRadius: "10px",
    margin: "5px",
    minHeight: "120px",
});
const TitleBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
});

const DashboardCard = ({ name, trackingValue, tracking2Value, defaultGrid = true, percent }) => {
    if (defaultGrid) { // For Umsatz and Umsatz Fertiggestellt
        return (
            <StyledPaper elevation={3}>
                <TitleBox>
                    <Typography variant="h6" sx={{ color: "#707070", mb: 0, pb: 0 }}>{name}</Typography>
                </TitleBox>
                <Box display={'flex'} flexDirection={'column'}>
                    <Box display={"flex"} flexDirection={'column'} alignItems={"start"} flexWrap={'wrap'}>
                        <Typography variant="h6" color={"#8C8C8C"} fontSize={'16px'}>Umsatz</Typography>
                        <Typography alignSelf={'end'} variant="h5">{trackingValue}</Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={'column'} alignItems={"start"} flexWrap={'wrap'}>
                        <Typography variant="h6" color={"#8C8C8C"} fontSize={'16px'}>Deckungsbeitrag</Typography>
                        <Typography alignSelf={'end'} variant="h5">{tracking2Value}</Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={'row'} justifyContent={"space-between"} alignItems={'center'} flexWrap={'wrap'}>
                        <Typography  variant="h5">{percent}</Typography>
                        <Typography variant="h6" color={"#8C8C8C"} fontSize={'16px'}>%</Typography>
                    </Box>

                </Box>
            </StyledPaper>
        );
    } else {
        return (
            <StyledPaper elevation={3}>
                <TitleBox>
                    <Typography variant="h6" sx={{ color: "#707070" }}>{name}</Typography>
                </TitleBox>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} flexWrap={'wrap'}>
                    <Typography variant="h6" color={"#8C8C8C"} fontSize={'16px'}>Total</Typography>
                    <Typography variant="h5">{trackingValue}</Typography>
                </Box>
            </StyledPaper>
        );
    }
}
export default DashboardCard