import * as React from "react";
import {
  Admin,
  Resource,
  ListGuesser,
  EditGuesser,
  defaultTheme,
  usePermissions,
} from "react-admin";
import { UserList, UserEdit, UserCreate } from "./components/Users";
import { WorkerList, WorkerEdit, WorkerCreate } from "./components/Workers";
import { CtypeList, CtypeEdit, CtypeCreate } from "./components/Ctypes";
import { PostList, PostEdit, PostCreate } from "./components/Posts";
import { RegionList, RegionEdit, RegionCreate } from "./components/Regions";
import { ProjectList, ProjectEdit, ProjectCreate } from "./components/Projects";

import {
  KontaktdatenList,
  KontaktdatenEdit,
  KontaktdatenCreate,
} from "./components/Kontaktdatens";
import {
  KundenlisteList,
  KundenlisteEdit,
  KundenlisteCreate,
} from "./components/Kundenlistes";
import {
  InactiveKundenlisteList
} from "./components/InactiveKundenlistes";
import { WorkList, WorkEdit, WorkCreate } from "./components/Works";
import { TaskList, TaskEdit, TaskCreate } from "./components/Tasks";

import { TrackList } from "./components/Tracks";
import { ReportList } from "./components/Reports";
import { FertiggestelltList } from "./components/fertiggestellt";
import { KostenList, KostenCreate, KostenEdit } from "./components/Kosten";
import Dashboard from "./components/Dashboard";
import MyLayout from "./layout/MyLayout";

import authProvider from "./authProvider";
import dataProvider from "./dataProvider";

import jsonServerProvider from "ra-data-json-server";
import PostIcon from "@mui/icons-material/Book";
import UserIcon from "@mui/icons-material/Group";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import EngineeringIcon from "@mui/icons-material/Engineering";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { PerformBewertungList } from "./components/PerformBewertung";
import { LeaderbordsList } from "./components/Leaderbords";

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
      },
    },
  },
};

const dataProviderData = jsonServerProvider(
  "https://jsonplaceholder.typicode.com"
);

const App = () => (
  <Admin
    theme={theme}
    layout={MyLayout}
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
  >
    {(permissions) => (
      <>
        <Resource
          name="regions"
          list={RegionList}
          recordRepresentation="name"
          create={
            permissions.access === "super" || permissions.access === "admin"
              ? RegionCreate
              : null
          }
          icon={FmdGoodIcon}
          edit={
            permissions.access === "super" || permissions.access === "admin"
              ? RegionEdit
              : null
          }
          options={{ label: "Standorte" }}
        />
        {permissions.access === "super" || permissions.access === "admin" ? (
          <Resource
            name="users"
            list={UserList}
            recordRepresentation="name"
            create={UserCreate}
            icon={UserIcon}
            edit={UserEdit}
            options={{ label: "Benutzer" }}
          />
        ) : null}

        <Resource
          name="projects"
          list={ProjectList}
          create={ProjectCreate}
          recordRepresentation="adresse"
          icon={ApartmentIcon}
          edit={ProjectEdit}
          options={{ label: "Projekte" }}
        />
        {permissions.access === "super" || permissions.access === "admin" ? (
          <Resource
            name="workers"
            list={WorkerList}
            recordRepresentation="name"
            create={WorkerCreate}
            icon={EngineeringIcon}
            edit={WorkerEdit}
            options={{ label: "Zuständig" }}
          />
        ) : null}
        {permissions.access === "super" || permissions.reportRegionsExist === true ? (
          <>
            <Resource
              name="reports"
              list={ReportList}
              icon={AssessmentIcon}
              options={{ label: "Umsätze" }}
            />
            <Resource
              name="fertiggestellt"
              list={FertiggestelltList}
              icon={AssessmentIcon}
              options={{ label: "Umsätze Fertiggestellt" }}
            />
          </>
        ) : null}
        {permissions.access === "super"  ? (
          <>
            <Resource
              name="kostens"
              list={KostenList}
              create={permissions.access === "super" ? KostenCreate : null}
              icon={PriceChangeIcon}
              edit={permissions.access === "super" ? KostenEdit : null}
              options={{ label: "Kosten" }}
            />
            <Resource
              name="ctypes"
              list={CtypeList}
              recordRepresentation="name"
              create={CtypeCreate}
              icon={MonetizationOnIcon}
              edit={CtypeEdit}
              options={{ label: "Cost Type" }}
            />
            <Resource
              name="tracks"
              list={TrackList}
              icon={GpsFixedIcon}
              options={{ label: "Tracking" }}
            />
          </>
        ) : null}
        {/* <Resource
          name="kontaktdatens"
          list={KontaktdatenList}
          recordRepresentation="name"
          create={permissions.access === "super" ? KontaktdatenCreate : null}
          icon={UserIcon}
          edit={permissions.access === "super" ? KontaktdatenEdit : null}
          options={{ label: "Kontaktdaten" }}
        /> */}
        <Resource
          name="kontaktdatens"
          list={KontaktdatenList}
          recordRepresentation="name"
          create={KontaktdatenCreate}
          icon={UserIcon}
          edit={KontaktdatenEdit}
          options={{ label: "Kontaktdaten" }}
        />
        <Resource
          name="kundenlistes"
          list={KundenlisteList}
          recordRepresentation="name"
          create={KundenlisteCreate}
          icon={UserIcon}
          edit={KundenlisteEdit}
          options={{ label: "Kundenliste" }}
        />
        <Resource
          name="works"
          list={WorkList}
          recordRepresentation="name"
          create={WorkCreate}
          icon={FormatListNumberedIcon}
          edit={WorkEdit}
          options={{ label: "Worklists" }}
        />
        <Resource
          name="tasks"
          list={TaskList}
          recordRepresentation="name"
          create={TaskCreate}
          icon={PlaylistAddCheckIcon}
          edit={TaskEdit}
          options={{ label: "Aufgaben" }}
        />
         <Resource
          name="inactivekundenlistes"
          list={InactiveKundenlisteList}
          recordRepresentation="name"
          icon={UserIcon}
          options={{ label: "Inaktive Kunden" }}
        />
        {permissions.access === "super" ? (
         <Resource
          name="leaderbords"
          list={LeaderbordsList}
          recordRepresentation="name"
          icon={UserIcon}
          options={{ label: "Leaderbords" }}
        />
        ) : null}
         {permissions.access === "super" || permissions.access === "admin" ? (
          <Resource
            name="performancebewertung"
            list={PerformBewertungList}
            options={{ label: "Performance Bewertung" }}
          />
        ) : null}
      </>
    )}
  </Admin>
);

export default App;
