import React, { useState } from 'react';
import {
    Grid,
    IconButton,
    Button,
    Box
} from '@mui/material';
import { CloudDownload as CloudDownloadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useParams } from "react-router-dom";
import { Datagrid, DateField, FunctionField, List, Pagination, TextField, TextInput, useNotify } from 'react-admin';
import PreviewFile from './ProjectFiles/PreviewFile';
import CreateFile from './ProjectFiles/CreateFile';
import config from '../config';

const apiUrl = config.apiUrl;
const imgApiUrl = config.imgApiUrl;

const FileList = () => {
    const params = useParams();
    const notify = useNotify();
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };
    const deleteFile = async (id) => {
        if (window.confirm('Are you sure you want to delete this File?')) {
            try {
                const response = await fetch(`${apiUrl}?section=uploads&action=delUploadedFiles&id=${id}`, {
                    method: 'GET',
                });
                const result = await response.json();
                if (result.errMsg) {
                    notify(result.errMsg, { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    return;
                }
                notify(result.msg, { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            } catch (error) {
                notify('Failed to delete files', { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            }
        }
    };
    const downloadFile = async (id, filename) => {
        try {
            const downloadUrl = `${apiUrl}?section=uploads&action=downloadUploadedFile&id=${id}`;
            const response = await fetch(downloadUrl);
            if (!response.ok) {
                notify('Error in getting file info', { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                return;
            }
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(blobUrl);
            link.remove();
            notify('File is downloading', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } catch (error) {
            notify('Error downloading file', { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
    };

    return (
        <Grid >
            <CreateFile apiUrl={apiUrl} open={openModal} handleClose={handleCloseModal} filesizes={filesizes} />
            <Box display={'flex'} justifyContent={'flex-end'} mb={0}>
                <Button onClick={handleOpenModal}>Upload File</Button>
            </Box>
            <List
                filters={projectFilters}
                perPage={10}
                pagination={<FilePagination />}
                actions={false}
                sort={{ field: "created_at", order: "DESC" }}
                resource={`uploads&action=index&id=${params.id}`}
                title={`=>Uploads`}
                empty={false}
            >
                <Datagrid bulkActionButtons={false} >
                    <TextField source="orignal_name" label="Name" sortable />
                    <TextField source="description" label="Description" sortable />
                    <FunctionField
                        source="size"
                        render={record => `${filesizes(record.size)}`}
                        sortable={false}
                    />
                    <PreviewFile label="Preview" imgApiUrl={imgApiUrl} sortable={false} />
                    <DateField source="created_at" label="Uploaded At" sortable />
                    <FunctionField
                        render={record => (
                            <IconButton onClick={() => deleteFile(record.id)}>
                                <DeleteIcon />
                            </IconButton>
                        )}
                        sortable={false}
                    />
                    <FunctionField
                        render={record => (
                            <IconButton onClick={() => downloadFile(record.id, record.orignal_name)}>
                                <CloudDownloadIcon />
                            </IconButton>
                        )}
                        sortable={false}
                    />
                </Datagrid>
            </List>
        </Grid>
    );
};

const projectFilters = [
    <TextInput label="Name" source="orignal_name" alwaysOn />,
    <TextInput label="Description" source="description" alwaysOn />
]
const FilePagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />;

export default FileList;
