import * as React from "react";
import { useRecordContext, ListButton } from "react-admin";
import ApartmentIcon from "@mui/icons-material/Apartment";

const RegionButton = ({ source }) => {
  const record = useRecordContext();

  return (
    <ListButton
      resource={`projects?filter={"regionId":"${record[source]}"}`}
      label="Projects"
      icon={<ApartmentIcon />}
    />
  );
};

export default RegionButton;
