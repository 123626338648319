import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  required,
  ListButton,
  TopToolbar,
  ReferenceField,
  ReferenceInput,
  Pagination,
  usePermissions,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";

const ProjectEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="< Zurück" />
  </TopToolbar>
);

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);

export const KontaktdatenList = () => {
  const { permissions } = usePermissions();
  return (
    <List
      filters={projectFilters}
      perPage={1000}
      pagination={<ProjectPagination />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="funktion" />
        <ReferenceField source="regionId" reference="regions" label="Region" />
        <TextField source="telefon" />
        <TextField source="mail" />
        {/* permissions.access === "super" ? <EditButton /> : null */}
        <EditButton />
      </Datagrid>
    </List>
  );
};

const KontaktdatenEditToolbar = (props) => {
  const { permissions } = usePermissions();
  return (
    <Toolbar {...props}>
      <SaveButton />
      {permissions.access === "super" ? <DeleteButton /> : null}
    </Toolbar>
  );
};

export const KontaktdatenEdit = (props) => (
  <Edit title="Edit Kontaktdaten" actions={<ProjectEditActions />}>
    <SimpleForm toolbar={<KontaktdatenEditToolbar />}>
      <TextInput disabled source="id" variant="outlined" />
      <ReferenceInput
        source="regionId"
        reference="regions"
        label="Region"
        validate={required()}
      />
      <TextInput label="Full Name" source="name" validate={required()} />
      <TextInput source="funktion" validate={required()} />
      <TextInput source="telefon" validate={required()} />
      <TextInput source="mail" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const KontaktdatenCreate = (props) => (
  <Create
    title="Add New Kontaktdaten"
    {...props}
    actions={<ProjectEditActions />}
  >
    <SimpleForm>
      <ReferenceInput
        source="regionId"
        reference="regions"
        label="Region"
        validate={required()}
      />
      <TextInput label="Full Name" source="name" validate={required()} />
      <TextInput source="funktion" validate={required()} />
      <TextInput source="telefon" validate={required()} />
      <TextInput source="mail" validate={required()} />
    </SimpleForm>
  </Create>
);

const projectFilters = [
  <ReferenceInput
    source="regionId"
    label="Region"
    reference="regions"
    alwaysOn
  />,
];
