import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  required,
  ListButton,
  TopToolbar,
  ReferenceField,
  ReferenceInput,
  usePermissions,
  Pagination,
  DateInput,
  DateField,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";

const ProjectEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="< Zurück" />
  </TopToolbar>
);

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);

export const KundenlisteList = () => {
  const { permissions } = usePermissions();
  return (
    <List
      filters={projectFilters}
      perPage={50}
      pagination={<ProjectPagination />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="regionId" reference="regions" label="Region" />
        <DateField source="kunde_seit" label="Kunde Seit" />
        {/* <TextField source="adresse" />
        <TextField source="telefonnummer" />
        <TextField source="ansprechpartner" />
        <TextField source="baustellen" />
        <TextField source="anmerkungen" label="Weitere Anmerkungen" /> */}
        {/* permissions.access === "super" ? <EditButton /> : null */}
        <EditButton />
      </Datagrid>
    </List>
  );
};

const KundenlisteEditToolbar = (props) => {
  const { permissions } = usePermissions();
  return (
    <Toolbar {...props}>
      <SaveButton />
      {permissions.access === "super" ? <DeleteButton /> : null}
    </Toolbar>
  );
};

export const KundenlisteEdit = (props) => (
  <Edit title="Edit Kundenliste" actions={<ProjectEditActions />}>
    <SimpleForm toolbar={<KundenlisteEditToolbar />}>
      <TextInput disabled source="id" variant="outlined" />
      <ReferenceInput
        source="regionId"
        reference="regions"
        label="Region"
        validate={required()}
      />
      <DateInput label="Kunde Seit" source="kunde_seit" validate={required()} />
      <TextInput label="Full Name" source="name" validate={required()} />
      <TextInput source="adresse" validate={required()} multiline fullWidth />
      <TextInput label="Spezielle Kundenwünsche" source="special_request" fullWidth multiline/>
      <TextInput source="telefonnummer" validate={required()} />
      <TextInput source="ansprechpartner" validate={required()} />
      <TextInput source="baustellen" validate={required()} />
      <TextInput source="anmerkungen" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const KundenlisteCreate = (props) => (
  <Create
    title="Add New Kundenliste"
    {...props}
    actions={<ProjectEditActions />}
  >
    <SimpleForm>
      <ReferenceInput
        source="regionId"
        reference="regions"
        label="Region"
        validate={required()}
      />
      <DateInput label="Kunde Seit" source="kunde_seit" validate={required()} />
      <TextInput label="Full Name" source="name" validate={required()} />
      <TextInput source="adresse" validate={required()} multiline fullWidth />
      <TextInput label="Spezielle Kundenwünsche" source="special_request" fullWidth multiline/>
      <TextInput source="telefonnummer" validate={required()} />
      <TextInput source="ansprechpartner" validate={required()} />
      <TextInput source="baustellen" validate={required()} />
      <TextInput source="anmerkungen" validate={required()} />
    </SimpleForm>
  </Create>
);

const projectFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput
    source="regionId"
    label="Region"
    reference="regions"
    alwaysOn
  />,
];
