import * as React from "react";
import {
  DateInput,
  List,
  ReferenceInput,
  useListContext,
} from "react-admin";
import { TableCell, TableRow, Table, TableBody } from "@mui/material";
import TrackingCards from "./TrackingCards";

export const TrackList = () => {
  return (
    <List filters={trackFilters} pagination={false}>
      <TarackCards />
    </List>
  );
}

const TarackCards = () => {
  const { data, isLoading } = useListContext();
  if (isLoading) {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Loading...</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }
  if (data.length === 0) {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>No data for this user</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }
  return (
    <>
      {
        data.map((item, index) => <TrackingCards key={item.id} record={item} />)
      }
    </>
  );
}

const trackFilters = [
  <ReferenceInput source="userId" label="User" reference="users" alwaysOn />,
  <DateInput source="start_date" alwaysOn />,
  <DateInput source="end_date" alwaysOn />
];