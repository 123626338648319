import * as React from "react";
import { Layout } from "react-admin";
import CustomAppBar from "./CustomAppBar";

const MyLayout = (props) => (
  <Layout
    sx={{ "& .RaLayout-appFrame": { marginTop: "90px" } }}
    {...props}
    appBar={CustomAppBar}
  />
);

export default MyLayout;
