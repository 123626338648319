import * as React from "react";
import { useRecordContext } from "react-admin";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";

const TrackField = ({ source }) => {
  const record = useRecordContext();
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: "/tracks",
        search: `filter=${JSON.stringify({ userId: record[source] })}`,
      }}
    >
      <GpsFixedIcon />
      Track
    </Button>
  ) : null;
};

export default TrackField;
