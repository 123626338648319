import * as React from "react";
import { useRecordContext, ListButton } from "react-admin";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

const WorkButton = ({ source }) => {
  const record = useRecordContext();

  return (
    <ListButton
      resource={`tasks?filter={"workId":"${record[source]}"}`}
      label="Tasks"
      icon={<PlaylistAddCheckIcon />}
    />
  );
};

export default WorkButton;
