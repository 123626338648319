import * as React from "react";
import EventIcon from "@mui/icons-material/Event";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  PasswordInput,
  UrlField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  Create,
  required,
  CloneButton,
  ReferenceInput,
  ReferenceField,
  NumberInput,
  RecordContextProvider,
  DatagridBody,
  DateField,
  DateInput,
  FilterListItem,
  FilterList,
  Pagination,
  useRecordContext,
  AutocompleteInput,
  ListButton,
  TopToolbar,
  SelectField,
  ArrayInput,
  SimpleFormIterator,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceArrayField,
  BooleanInput,
  useUpdate,
  useRedirect,
  Toolbar,
  SaveButton,
  useNotify,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { TableCell, TableRow, Checkbox } from "@mui/material";


const MyDatagridRow = ({
  record,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
}) => (
  <RecordContextProvider value={record}>
    <TableRow>
      {/* first column: selection checkbox */}

      <TableCell padding="none">
        <Checkbox
          disabled={selectable}
          checked={selected}
          onClick={(event) => onToggleItem(id, event)}
        />
      </TableCell>
      {/* data columns based on children */}
      {React.Children.map(children, (field) => (
        <TableCell
          key={`${id}-${field.props.source}`}
          style={
            record.id == 0 && field.props.source == "abt"
              ? {
                  background: "red",
                  color: "#fff",
                }
              : record.abt == "Total"
              ? {
                  background: "green",
                  color: "#fff",
                }
              : {}
          }
        >
          {field}
        </TableCell>
      ))}
    </TableRow>
  </RecordContextProvider>
);
const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);
const MyDatagrid = (props) => {
  return (
    <>
      <Datagrid {...props} body={<MyDatagridBody />} />
    </>
  );
};

const KostenPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);

const RowEditCloneBut = ({ source }) => {
  return (
    <>
      <EditButton label="" />
      <CloneButton label="" />
    </>
  );
};

export const KostenList = () => (
  <List 
    filters={kostenFilters}      
    perPage={50}
    pagination={<KostenPagination />}
  >
    <MyDatagrid>
      <TextField source="abt" label="What" />
      <SelectField
        source="frq"
        label="Frequency"
        choices={[
          { id: "1", name: "One time" },
          { id: "2", name: "Monthly Recurring" },
          { id: "3", name: "Yearly Recurring" },
        ]}
      />
      <TextField source="comma_separated_ctype_name" label="Kosten Ctype" />
      <TextField source="s_time" label="Starting time" />
      <TextField source="new_total" label="Sum in euro" />
      <RowEditCloneBut source="e_c_but" label="" />
    </MyDatagrid>
  </List>
);
const yearFilter = () => {
  const curYear = parseInt(new Date().getFullYear());
  const yearLimit = [curYear - 10, curYear + 10];
  let years = [];
  for (let i = yearLimit[0]; i <= yearLimit[1]; i++) {
    years.push({ id: i, name: i });
  }
  return years;
};
const kostenFilters = [
  <SelectInput
    source="month"
    label="Month"
    alwaysOn
    choices={[
      { id: "01", name: "January" },
      { id: "02", name: "February" },
      { id: "03", name: "March" },
      { id: "04", name: "April" },
      { id: "05", name: "May" },
      { id: "06", name: "June" },
      { id: "07", name: "July" },
      { id: "08", name: "August" },
      { id: "09", name: "September" },
      { id: "10", name: "October" },
      { id: "11", name: "November" },
      { id: "12", name: "December" },
    ]}
  />,
  <SelectInput source="year" label="Year" alwaysOn choices={yearFilter()} />,
  <ReferenceInput
    source="c_type"
    label="Type of cost"
    alwaysOn
    reference="ctypes"
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
];

const KostenEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton label="< Zurück" />
  </TopToolbar>
);
const PostEditToolbar = ({ srcFrom }) => {
  const [update] = useUpdate();
  const notify = useNotify();
  const { getValues } = useFormContext();
  const redirect = useRedirect();
  const handleClick = (e) => {
    e.preventDefault(); 
    const { id, ...data } = getValues();
    data.e_time = data.continued ? "" : data.e_time;
    const {ttl, e_time, s_time, performance_bonuses, continued, frq, abt} = data;
    let notificationShown = false;
    if (abt === null || abt === undefined || abt.trim() === "") {
      notify("What section cant be empty", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, type: 'error' });
      return;
    }
    if (frq === null || frq === undefined) {
      notify("Frequency cant be empty", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, type: 'error' });
      return;
    }
    if (s_time === null || s_time === undefined) {
      notify("Starting time cant be empty", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, type: 'error' });
      return;
    }
    if (continued === false && (e_time === null || e_time === undefined)) {
      notify("Ending time cant be empty", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, type: 'error' });
      return;
    }
    if (ttl === null || ttl === undefined || ttl < 0) {
      notify("Total cant be negative or empty", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, type: 'error' });
      notificationShown = true;
      return;
    }
    if (continued === false && (e_time < s_time)) {
      notify("Ending time must equal to or after starting time", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, type: 'error' });
      notificationShown = true;
      return;
    }
    if(frq === "2" && performance_bonuses.length > 0) {
      let etime = e_time.split('-');
      let stime = s_time.split('-');
      performance_bonuses.forEach(bonus => {
          if ((parseInt(etime[0]) < parseInt(bonus.year) || parseInt(stime[0]) > parseInt(bonus.year)) && continued === false) {
              notify("Bonus must be after starting and before ending date", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, type: 'error' });
              notificationShown = true;
              return;
          }
          if ((parseInt(bonus.month) > parseInt(etime[1]) || parseInt(bonus.month) < parseInt(stime[1])) && continued === false) {
              notify("Bonus must be after starting and before ending date", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, type: 'error' });
              notificationShown = true;
              return;
          }
          if (continued === true && (parseInt(stime[0]) > parseInt(bonus.year))) {
            notify("Bonus must be after starting date", { anchorOrigin: { vertical: 'top', horizontal: 'right' }, type: 'error' });
            notificationShown = true;

            return;
          }
      });
    }
    if (!notificationShown) {
      update(
        "kostens",
        { id, data: { id, ...data } },
        {
          onSuccess: () => {
            notify("Kosten successfully updated", {
              type: "success",
            });
            redirect('/kostens');
          },
          onFailure: (error) => {
            notify(error.message, {
              type: "error",
            });
          },
        }
      );
    }
   
  };
  return (
    <Toolbar>
      <SaveButton alwaysEnable label="Save"  type="button" onClick={handleClick} />
    </Toolbar>
  );
};
export const KostenEdit = (props) => (
  <Edit title="Edit Kosten" actions={<KostenEditActions />}>
    <SimpleForm toolbar={<PostEditToolbar srcFrom="edit" />}>
      <TextInput disabled source="id" variant="outlined" />
      <TextInput label="What" source="abt" validate={required()} />
      <SelectInput
        validate={required()}
        source="frq"
        label="Frequency"
        choices={[
          { id: "1", name: "One time" },
          { id: "2", name: "Monthly Recurring" },
          { id: "3", name: "Yearly Recurring" },
        ]}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData.frq === "2") { // Check if Monthly Recurring is selected
            return (
              <ArrayInput source="performance_bonuses" label="Performance Bonus">
                <SimpleFormIterator inline>
                  <SelectInput validate={required()}  source="year" label="Year" alwaysOn choices={yearFilter()} />,
                  <SelectInput
                   source="month"
                   label="Month"
                   validate={required()} 
                   alwaysOn
                   choices={[
                     { id: "01", name: "January" },
                     { id: "02", name: "February" },
                     { id: "03", name: "March" },
                     { id: "04", name: "April" },
                     { id: "05", name: "May" },
                     { id: "06", name: "June" },
                     { id: "07", name: "July" },
                     { id: "08", name: "August" },
                     { id: "09", name: "September" },
                     { id: "10", name: "October" },
                     { id: "11", name: "November" },
                     { id: "12", name: "December" },
                   ]}
                  />
                  <NumberInput
                    label="Bonus"
                    source="bonus"
                    validate={required()} 
                  />
                </SimpleFormIterator>
              </ArrayInput>
            );
          }
          return null; // Return null if Monthly Recurring is not selected
        }}
      </FormDataConsumer>
      <ReferenceArrayInput
        source="ctypes"
        reference="ctypes"
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <DateInput label="Starting Time" source="s_time" validate={required()} />
      <BooleanInput label="Continued" source="continued" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData.continued === false) { 
            return (
              <DateInput label="End Time" source="e_time" validate={required()} />
            );
          }
          return null; 
        }}
      </FormDataConsumer>
      <NumberInput label="Amount" source="ttl" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const KostenCreate = (props) => (
  <Create title="Add New Kosten" {...props} actions={<KostenEditActions />}>
    <SimpleForm toolbar={<PostEditToolbar srcFrom="new" />}>
      <TextInput disabled source="id" variant="outlined" />
      <TextInput label="What" source="abt" validate={required()} />
      <SelectInput
        validate={required()}
        source="frq"
        label="Frequency"
        choices={[
          { id: "1", name: "One time" },
          { id: "2", name: "Monthly Recurring" },
          { id: "3", name: "Yearly Recurring" },
        ]}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData.frq === "2") { // Check if Monthly Recurring is selected
            return (
              <ArrayInput source="performance_bonuses" label="Performance Bonus">
                <SimpleFormIterator inline>
                  <SelectInput validate={required()}  source="year" label="Year" alwaysOn choices={yearFilter()} />,
                  <SelectInput
                   source="month"
                   label="Month"
                   validate={required()} 
                   alwaysOn
                   choices={[
                     { id: "01", name: "January" },
                     { id: "02", name: "February" },
                     { id: "03", name: "March" },
                     { id: "04", name: "April" },
                     { id: "05", name: "May" },
                     { id: "06", name: "June" },
                     { id: "07", name: "July" },
                     { id: "08", name: "August" },
                     { id: "09", name: "September" },
                     { id: "10", name: "October" },
                     { id: "11", name: "November" },
                     { id: "12", name: "December" },
                   ]}
                  />
                  <NumberInput
                    label="Bonus"
                    source="bonus"
                    validate={required()} 
                  />
                </SimpleFormIterator>
              </ArrayInput>
            );
          }
          return null; // Return null if Monthly Recurring is not selected
        }}
      </FormDataConsumer>
      <ReferenceArrayInput
        source="ctypes"
        label="Type of cost"
        reference="ctypes"
        validate={required()}
      >
        {/* <AutocompleteInput optionText="name" /> */}
      </ReferenceArrayInput>
      <DateInput label="Starting Time" source="s_time" validate={required()} />
      <BooleanInput label="Continued" source="continued" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData.continued === false) { 
            return (
              <DateInput label="End Time" source="e_time" validate={required()} />
            );
          }
          return null; 
        }}
      </FormDataConsumer>
      <NumberInput label="Amount" source="ttl" validate={required()} />
    </SimpleForm>
  </Create>
);
