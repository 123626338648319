import React, { useRef, useState, useEffect } from "react";
import {
  useRedirect,
  useRefresh,
  SelectInput,
  BulkUpdateButton,
  useListContext,
  useUpdateMany,
  useUnselectAll,
  useNotify,
} from "react-admin";
import { Button, TextField } from "@mui/material";
import dataProvider from "../dataProvider";
import NativeSelect from "@mui/material/NativeSelect";

const ActionInline = ({ field, record, id }) => {
  const { selectedIds } = useListContext();
  const notify = useNotify();
  const unselectAll = useUnselectAll("projects");

  const [editValues, setEditValues] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [updateMany, { isLoading }] = useUpdateMany(
    "projects",
    { ids: selectedIds, data: editValues },
    {
      onSuccess: () => {
        refresh();
        notify("Selected projects updated");
        unselectAll();
      },
      onError: (error) =>
        notify("Error: projects not updated", { type: "warning" }),
    }
  );
  return (
    <div className="akprj_bul">
      {!showForm && (
        <div>
          <Button
            variant="contained"
            onClick={() => {
              setShowForm(true);
            }}
          >
            Update
          </Button>
        </div>
      )}
      {showForm && (
        <div className="bulkEditRow">
          <NativeSelect
            label="Farbe (color)"
            className="list_farbe"
            onChange={(e) =>
              setEditValues({ ...editValues, farbe: e.target.value })
            }
          >
            <option value={0}></option>
            <option value={1}> . . . . . . . . . </option>
            <option value={2}></option>
            <option value={3}></option>
            <option value={4}></option>
            <option value={5}></option>
            <option value={6}></option>
            <option value={7}></option>
            <option value={8}></option>
            <option value={9}></option>
          </NativeSelect>{" "}
          &nbsp;
          <TextField
            label={"Action"}
            onChange={(e) =>
              setEditValues({ ...editValues, action: e.target.value })
            }
            multiline
          />{" "}
          &nbsp;
          <TextField
            label=""
            onChange={(e) =>
              setEditValues({ ...editValues, action_date: e.target.value })
            }
            variant="outlined"
            type="date"
          />{" "}
          &nbsp;
          <Button
            label="simple.action.resetViews"
            disabled={isLoading}
            onClick={() => updateMany()}
            variant="contained"
          >
            Update All
          </Button>
        </div>
      )}
    </div>
  );
};

export default ActionInline;
