import * as React from "react";
import EventIcon from "@mui/icons-material/Event";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  PasswordInput,
  UrlField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  Create,
  required,
  CloneButton,
  ReferenceInput,
  ReferenceField,
  NumberInput,
  RecordContextProvider,
  DatagridBody,
  DateField,
  DateInput,
  FilterListItem,
  FilterList,
  Pagination,
  useRecordContext,
  AutocompleteInput,
  AutocompleteArrayInput
} from "react-admin";
import { TableCell, TableRow, Checkbox } from "@mui/material";
import { AdminField, AkLinkField } from "./AdminField";
import PassField from "./PassField";

let cntCur = 0;

const MyDatagridRow = ({
  record,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
}) => (
  <RecordContextProvider value={record}>
    <TableRow>
      {/* first column: selection checkbox */}

      <TableCell padding="none">
        <Checkbox
          disabled={selectable}
          checked={selected}
          onClick={(event) => onToggleItem(id, event)}
        />
      </TableCell>
      {/* data columns based on children */}
      {React.Children.map(children, (field) => (
        <TableCell
          key={`${id}-${field.props.source}`}
          style={
            record.id == 0 && field.props.source == "auftragseingang"
              ? {
                  background: "red",
                  color: "#fff",
                }
              : record.id == "Total"
              ? {
                  background: "green",
                  color: "#fff",
                }
              : {}
          }
        >
          {field}
        </TableCell>
      ))}
    </TableRow>
  </RecordContextProvider>
);
const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);
const MyDatagrid = (props) => {
  return (
    <>
      <Datagrid {...props} body={<MyDatagridBody />} />
    </>
  );
};

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);

const CurrentIndexField = (props) => {
  const record = useRecordContext(props);
  if (record.id == "Total" || record.id == 0) {
    cntCur = 0;
    return "";
  }
  return <span>{(cntCur = cntCur + 1)}</span>;
};

export const ReportList = () => (
  <List filters={projectFilters} perPage={1000} pagination={false}>
    <MyDatagrid>
      <CurrentIndexField label="#" />
      <TextField source="id" label="Auftragsnummer" />
      <TextField source="auftragseingang" label="Auftragseingang" />
      <AkLinkField source="k_" res="kundenlistes" label="Kunde" />
      <TextField source="adresse" label="Adresse" />
      <TextField source="gewerk" label="Gewerk" />
      <TextField source="umsatz" label="Umsatz" />
      <TextField source="deckungsbeitrag" label="Deckungsbeitrag" />
      <PercentageField label="%" source={'percentage'} />
      {/* <TextField source="zustandig" label="Zuständig" /> */}
    </MyDatagrid>
  </List>
);
const PercentageField = ({  source }) => {
  const record = useRecordContext()
  return (<span>{record[source]}%</span>)
};
const yearFilter = () => {
  const curYear = parseInt(new Date().getFullYear());
  const yearLimit = [curYear - 10, curYear + 10];
  let years = [
    { id: "this", name: "Year until Today" },
    { id: "last", name: "Last Year" },
  ];
  for (let i = yearLimit[0]; i <= yearLimit[1]; i++) {
    years.push({ id: i, name: i });
  }
  return years;
};
const projectFilters = [
    <ReferenceInput
    source="regionId"
    reference="reportRegions"
    alwaysOn
  >
    <AutocompleteInput optionText="name" label="Region" />
  </ReferenceInput>,
  <SelectInput
    source="month"
    label="Month"
    alwaysOn
    choices={[
      { id: "01", name: "January" },
      { id: "02", name: "February" },
      { id: "03", name: "March" },
      { id: "04", name: "April" },
      { id: "05", name: "May" },
      { id: "06", name: "June" },
      { id: "07", name: "July" },
      { id: "08", name: "August" },
      { id: "09", name: "September" },
      { id: "10", name: "October" },
      { id: "11", name: "November" },
      { id: "12", name: "December" },
    ]}
  />,
  <SelectInput source="year" label="Year" alwaysOn choices={yearFilter()} />,
  <ReferenceInput
    source="zustandig"
    label="Zuständig"
    alwaysOn
    reference="workers"
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    source="kunde"
    label="Kunde"
    reference="kundenlistes"
    alwaysOn
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <AutocompleteArrayInput
    source="farbe"
    alwaysOn
    label="Farbe (color)"
    className="project-color-filter"
    choices={[
      { id: 0, name: <span className="farbess farbe0">.</span> },
      { id: 1, name: <span className="farbess farbe1">.</span> },
      { id: 2, name: <span className="farbess farbe2">.</span> },
      { id: 3, name: <span className="farbess farbe3">.</span> },
      { id: 4, name: <span className="farbess farbe4">.</span> },
      { id: 5, name: <span className="farbess farbe5">.</span> },
      { id: 6, name: <span className="farbess farbe6">.</span> },
      { id: 7, name: <span className="farbess farbe7">.</span> },
      { id: 8, name: <span className="farbess farbe8">.</span> },
      { id: 9, name: <span className="farbess farbe9">.</span> },
    ]}
  />,
  <SelectInput
    source="gewerk"
    label="Gewerk"
    alwaysOn
    choices={[
      { id: "DE", name: "DE" },
      { id: "DE+TR", name: "DE+TR" },
      { id: "TR", name: "TR" },
      { id: "FM", name: "FM" },
      { id: "LO", name: "LO" },
      { id: "SchU", name: "SchU" },
      { id: "WHST", name: "WHST" },
      { id: "Sonstige", name: "Sonstige" },
    ]}
  />,
];
