import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  required,
  ListButton,
  TopToolbar,
  Pagination,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

const ProjectEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="< Zurück" />
  </TopToolbar>
);

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);

export const WorkerList = () => (
  <List perPage={1000} pagination={<ProjectPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="wcategory_id" label="Category" reference="workerCategories">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

export const WorkerEdit = (props) => (
  <Edit title="Edit Worker" actions={<ProjectEditActions />}>
    <SimpleForm>
      <TextInput disabled source="id" variant="outlined" />
      <TextInput label="Full Name" source="name" validate={required()} />
      <ReferenceInput
        source="wcategory_id"
        reference="workerCategories"
      >
        <AutocompleteInput optionText="name" label="Category" sx={{minWidth:'250px'}} validate={required()}/>
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const WorkerCreate = (props) => (
  <Create title="Add New Worker" {...props} actions={<ProjectEditActions />}>
    <SimpleForm>
      <TextInput label="Full Name" source="name" validate={required()} />
      <ReferenceInput
        source="wcategory_id"
        reference="workerCategories"
      >
        <AutocompleteInput optionText="name" label="Category" sx={{minWidth:'250px'}} validate={required()}/>
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
