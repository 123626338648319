import * as React from "react";
import { useRecordContext, useCreatePath } from "react-admin";
import { Link } from "react-router-dom";

const AdminField = ({ source }) => {
  const record = useRecordContext();
  const uTypes = ["Super Admin", "Admin", "User"];
  return record ? uTypes[record[source]] : null;
};

export default AdminField;

export const AkLinkField = ({ source, res }) => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  const uTypes = ["Super Admin", "Admin", "User"];
  return record ? (
    <Link
      to={createPath({
        resource: res,
        type: "show",
        id: record[source + "id"],
      })}
    >
      {record[source + "name"]}
    </Link>
  ) : null;
};

export const AkMultiLinkField = ({ source, res }) => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  if (record && record[source + "ids"] && record[source + "names"]) {
    let ids = record[source + "ids"].split(",");
    let names = record[source + "names"].split(",");
    if (ids.length > 0) {
      return ids.map(function (cur_id, index) {
        return (
          <>
            <Link
              to={createPath({
                resource: res,
                type: "show",
                id: cur_id,
              })}
            >
              {names[index]}
            </Link>
            , &nbsp;
          </>
        );
      });
    }
  }
  return null;
};
